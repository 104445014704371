import React, {useContext} from "react";
import { ContextApp } from "../ContextAPI";
import { NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

function SideBarItem({item}) {
    const { appState, setAppState} = useContext(ContextApp);
    // const navigate = useNavigate()
    const location = useLocation()
    console.log(item, "apart from item ", location)
    return (
      item.sidebarProps && item.path ? (
       <NavHashLink
       to={(appState != item.state) ? (item.path + '#top'):location.pathname+location.search}
       className=" cursor-pointer hover:text-green-500 text-gray-300 mx-auto   py-2 m-2 rounded-sm pl-4 select-none" 
       style={appState === item.state?{ display:'block', color:"#22C55E", fontWeight:'600'}:{display:'block'}}
       >
     {item.sidebarProps.icon}
        </NavHashLink>
      ) : null
    );
}

export default SideBarItem